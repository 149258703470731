/* If the screen is small than X, then use these css settings. */
@media only screen and (max-width: 550px) {
    .settingsWrapper {
        width: 100%;
        margin: auto;
    }
}

/* If the screen is greater than X, then use these css settings */
@media only screen and (min-width: 550px) {
    .settingsWrapper {
        /*width: 60%;*/
        min-width: 500px;
        max-width: 500px;
        margin: auto;
    }
}

.settings {
    margin-top: 0px;
    margin-bottom: 30px;
}
.settings_row {
    padding: 25px;
    display: flex;
    justify-content: space-between;

    border-bottom: 1px solid rgba(128, 128, 128, 0.3);
}

/*
.settings_row:nth-child(n+2) {
    border-top: 1px solid rgba(128, 128, 128, 0.3);
}*/

.settings_typography {
    margin-top: 200px;
    border-top: 1px solid rgba(128, 128, 128, 0.3);
}


.logotesttoo {
    margin-top: 30px;

    display: block;
    background: url('/Meeples.svg');

    fill: lightblue !important;
    width: 100px;
    height: 100px;
    background-size: 100% 100%;

    color: red;
    background-color: green;
}

.logotesttoo svg {
    fill: crimson !important;
}

.logotesttoo svg path {
    fill: crimson !important;
}
