.statsbox_scores {
    padding-top: 20px;
    flex-grow: 2;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
}

.statsbox_table {
    width: 100%;
    margin-top: 5px;
}

.statsbox_trheader {
}
.statsbox_tdheader {
}
.statsbox_tdsorticon {
    width: 24px;
}
.statsbox_tdheader_span {
    display: flex;
    /*justify-content: space-between;*/
}
.statsbox_iconspan {
    width: 24px;
}

.statsbox_tr {
}
.statsbox_tdtitle {

}
.statsbox_tdwins {
    width: 75px;
}
.statsbox_tdnumplayed {
    width: 75px;
}
.statsbox_tdwinrate {
    width: 75px;
}

