.google_btn {
    margin-bottom: 10px;

    cursor: pointer;

    display: inline-block;
    line-height: normal;

    min-height: 40px;
    width: 100%;
    padding: 8px 16px;
    background-color: #fff;

    border: none;
    border-radius: 2px;
    color: #000;

    box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12);
}
.google_icon_wrapper {
    display: table-cell;
    vertical-align: middle;

    padding-left: 4px;

    height: 18px;
    width: 18px;
}
.google_icon {
    height: 18px;
    width: 18px;
}
.google_text {
    display: table-cell;
    vertical-align: middle;
    width: 100%;

    color: #757575;
    text-transform: none;
    padding-left: 8px;

    font-family: Roboto,Helvetica,Arial,sans-serif;
    font-weight: 500;
    font-size: 14px;
}