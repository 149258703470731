* {
    box-sizing: border-box;
}

.mktgbody {
    width: 100%;
    margin: auto;
}

.apphome_background {
    width: 100%;
    height: 50%;
    background-image: url('/clipPath.svg');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 10px;
    margin-bottom: 20px;
}

/*.header {
   width: 100%;
   height: 50%;
   display: block;
   padding: 40px;
 }*/
.apphome_wrap1 {
    margin: auto;
    margin-top: 10%;
    margin-bottom: 10%;
    text-align: center;
}

.apphome_title {
    font-size: 100px;
    color: white;
    text-align: center;
}

.apphome_nav {
    background: #2196f3;
    color: white;
    padding: 10px;
    position: fixed;
    text-align: right;
    padding-right: 40px;
    padding-left: 20px;
    z-index: 12;
    height: 64px;
    width: 100%;
    display: flex;
    justify-content: space-between;
}
