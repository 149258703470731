/* Global Styles only here please */

* {
    box-sizing: border-box;
    /*transition: 0.5s;*/
}
html, body {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
}
table {
    border-spacing: 0;
}

.footer {
    text-align: center;
    align-self: center;
    margin-bottom: 30px;
}

.errorMsg {
    background: lightcoral;
    padding: 50px;
    margin: 30px;
    border: solid 1px;
}

/*a:-webkit-any-link {*/
a {
    cursor: pointer;
    text-decoration: none;
    /*font-weight: bold;*/
}

/*.apphome_background { */
.meeplesImage {
    padding: 8px 16px;
    height: 23px;

    background-image: url('/Meeples.svg');
    background-size: contain;
    background-repeat: no-repeat;
}

/**
 * AppBar
 */

.appbar_title {
    white-space: nowrap;      /*keep text on one line */
    overflow: hidden;         /*prevent text from being shown outside the border */
    text-overflow: ellipsis;  /*cut off text with an ellipsis*/
    display: block;           /*ellipsis does not work on inline elements, must be block*/
}

/* If the screen is small than X, then use these css settings. */
@media screen and (max-width: 650px) {
    .appbar_title {
        display: none !important;
    }
    .appbar_title_meeples {
        /*display: inherit !important;*/
    }
}

/* If the screen size more than ... */
@media screen and (min-width: 650px) {
    .appbar_title {
        /*display: block !important;*/
    }
    .appbar_title_meeples {
        display: none !important;
    }
}
