.react-rater-star {
  cursor: pointer;
  color: #ccc;
  position: relative; }
  .react-rater-star.will-be-active {
    color: #3E5E7B; }
  .react-rater-star.is-active {
    color: #527DA4; }
  .react-rater-star.is-active-half:before {
    color: #527DA4;
    content: '\2605';
    position: absolute;
    left: 0;
    width: 50%;
    overflow: hidden; }
  .react-rater-star.is-disabled {
    cursor: default; }
