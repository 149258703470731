.gamelistview {
    width: 80%;
    max-width: 750;
    margin: auto;
    margin-top: 30px;
/*    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',*/
}
.gamelist {
    width: 100%;
    padding-bottom: 25px;
}
.gamelist_heading {
    line-height: 100px;
    /*padding-left: 24px;*/
}
.gamelist_grid {
    padding-left: 24px;
    display: flex;
    flex-wrap: wrap;
}

/* need the wrapper to have the padding, so the child div can do overflow: hidden */
.gridbox_wrapper {
    width: 246px;
    height: 180px;
    padding: 2px;
}
.gridbox {
    height: 100%;
    display: block;
    overflow: hidden;
    position: relative; /* makes position.absolute on title below work */
}
.gridbox_img {
    top: 50%;
    width: 100%;
    position: relative;
    transform: translateY(-50%);
}
.gridbox_title {
    bottom: 0;
    left: 0;
    right: 0;
    height: 48px;
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
}