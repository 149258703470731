.apphome_authform {
    display: flex;
    flex-direction: column;
    /*justify-content: space-evenly;*/
    align-items: center;
    padding-bottom: 70px;
}
.apphome_authform_title {
    min-width: 350px;
    margin-bottom: 20px;
}
.apphome_authform_content {
    /*width: 300px;
    margin-left: 33%;*/
    /*margin: 0 auto;*/
    min-width: 350px;
}
.auth_form {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}
.formControl {
    margin-bottom: 20px;
}

.apphome_section {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding-bottom: 70px;
}
.apphome_section_leftimage {
    width: 300px;
    margin-right: 30px;
}
.apphome_section_rightimage {
    width: 300px;
    margin-left: 30px;
}

.apphome_section_bottom {
    width: 100%;
    max-width: 500px;
    align-self: center;
    text-align: center;
    /*margin: auto;*/
}

.login {
    max-width: 350px;
    display: flex;
    
    flex-direction: column;
    justify-content: center;
}

.apphome_useemail {
    display: inline-block;
    line-height: normal;

    cursor: pointer;
    min-height: 40px;
    width: 100%;
    padding: 8px 16px;

    border: none;
    border-radius: 2px;
    color: #000;

    box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12);

    background: white;
}
.apphome_useemail_icon {
    display: table-cell;
    vertical-align: middle;

    margin-left: 8px;
    height: 18px;
    width: 18px;
}
.apphome_useemail_text {
    display: table-cell;
    vertical-align: middle;
    width: 100%;

    text-transform: none;
    padding-left: 16px;

    font-family: Roboto,Helvetica,Arial,sans-serif;
    font-weight: 500;
    font-size: 14px;
}

.login_with_email_label{
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
}

/* If the screen is small than X, then use these css settings. */
@media screen and (max-width: 750px) {

    .apphome_section {
        flex-direction: column;
        padding-right: 5%;
        padding-left: 5%;
        align-items: flex-start;
    }

    .apphome_imagediv {
        width: 100%;
        text-align: center;
    }
}
  
/* If the screen size more than ... */
@media screen and (min-width: 750px) {

    .apphome_section {
        flex-direction: row;
        padding-right: 0%;
        padding-left: 0%;
        align-items: center;
    }

    .apphome_imagediv {
        width: inherit;
        text-align: inherit;
    }
}
  