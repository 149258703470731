.ftu {
    max-width: 500px;
    padding-top: 75px;
    margin-left: 10%;

    display: flex;
    flex-direction: column;
}

.ftu_row {
    width: 100%;
    max-width: 325px;
    padding-bottom: 50px;
}

.ftu_row_button {
    padding-top: 15px;
    padding-left: 35%;
}