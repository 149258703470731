/* If the screen is small than X, then use these css settings. */
@media only screen and (max-width: 550px) {
    .friendbox {
        width: 100%;
        margin: auto;
    }
}

/* If the screen is greater than X, then use these css settings */
@media only screen and (min-width: 550px) {
    .friendbox {
        /*width: 60%;*/
        min-width: 500px;
        max-width: 500px;
        margin: auto;
    }
}


.friendslist {
}
.friendslistlist {
}
.friendbox {
    /*
    background-color: white;

    margin-bottom: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    padding: 30px;
    */

    border-bottom: 1px solid rgba(128, 128, 128, 0.3);
    padding: 25px;
    margin-bottom: 20px;

    font-family: "Futura";
    color: #3C3C3C;
    font-weight: normal;
  
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;  
}

.friendboxlist {
    flex: 0 0 100%;
    margin-top: 15px;
    margin-left: 10px;
}

.friendrow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.friendslist_progress {
    min-width: 64px;
    padding: 4px 5px;

    width: 100%;
    display: flex;
    justify-content: center;
}