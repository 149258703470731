.sessionGame {
    width: 100%;
    height: 300px;
    text-align: left;
}

.addSessionWrapper {
    text-align: center;
    background-color: white;
    padding-bottom: 50px;
}
.addSession {
    width: 450px;
    /*padding: 20px;*/
    display: inline-block;
    text-align: left;   /* reset back to default, addSessionWrapper uses to center, but messes with text fields */
}
.addSessionRow {
    padding-bottom: 10px;
    display: flex;
}
.gameNameRow {
    margin-top: 10px;
    margin-bottom: 10px;

    display: flex;
    flex-direction: column;
}
.addSession_expansionsdiv {
    display: flex;
}
.dateRow {
    padding-top: 10px;
    justify-content: flex-end;
}
.buttonRow {
    padding-top: 15px;
    display: flex;
    justify-content: space-between;
}

/**** 
 * Session Table
 */
.sessiontable_div {
    width: 100%;
}
.sessiontable_btns {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}
.sessiontable_table {
    width: 100%;
    border: none;
}
.sessiontable_tr:hover {
    outline-style: dashed;
}
.sessiontable_td {
    padding-left: 30px;
    padding-right: 30px;
}

/* If the screen is small than X, then use these css settings. */
@media only screen and (max-width: 500px) {
    .addSession {
        width: 100%;
        padding: 0px;
    }

    .gameNameRow {
        padding-right: 10px;
        padding-left: 10px;
    }

    .sessiontable_btns {
        padding-right: 10px;
        padding-left: 10px;
    }
    .dateRow {
        padding-right: 10px;
        padding-left: 10px;
    }
    .locationRow {
        padding-right: 10px;
        padding-left: 10px;
    }
    .buttonRow {
        padding-right: 10px;
        padding-left: 10px;
        padding-bottom: 20px;
    }
}
