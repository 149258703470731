.viewsession {
    background-color: white;

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
}

.viewsession_dateloc {
    margin-top: 5px;
    text-align: center;
}
.viewsession_edit {
    margin-top: 5px;
    text-align: center;
}

.viewscores {
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
}
.viewscores_table {
    width: 100%;
    border: none;
}
.vievscores_td {
    padding-left: 30px;
    padding-right: 30px;
}

.vs_playerscore_winner {
    font-weight: bold;
}

.vs_playerscore {
    font-style: italic;
}

/** GroupedComment **/
.viewsession_bottom {
    margin-bottom: 100px;
}
.discussion {
    /*margin-left: 8%;
    margin-right: 8%;*/
    padding-left: 8%;
    padding-right: 8%;

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
}
.comment_authorname {
    align-self: flex-start;
    text-transform: uppercase;
    margin-top: 5px;
}
.comment_content {
    align-self: flex-start;
    border-radius: 22px;
    padding: 13px 18px;
    margin-bottom: 5px;
}
.comment_content_user {
    align-self: flex-end;
    padding: 13px 18px;
    border-radius: 30px;
    margin-bottom: 5px;
}
.comment_authorname_user {
    align-self: flex-end;
    margin-top: 5px;
}

.addcomment {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
    border-radius: 30px;
    border-width: 2px;
    border-style: solid;
    padding: 5px 22px;
}
.addcomment_input {
    width: 100%;
    border-style: none;
    background-color: inherit;
}
.addcomment_input:focus {
    outline: none;
}

/* If the screen is greater than X, then use these css settings */
@media only screen and (min-width: 650px) {

    .viewsession_top {
        width: 100%;
        margin-top: 20px;
        padding-left: 5%;
        padding-right: 5%;

        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
    }
    .viewsession_game {
        
    }
    .viewsession_left {
        width: 50%;
        padding-right: 5%;
    }
    .viewsession_right {
        width: 50%;
        display: flex;
        flex-direction: column;
    }
    .viewsession_dateloc {

    }
    .viewsession_edit {

    }
    .viewscores {
        order: -1;
    }
}