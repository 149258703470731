.afDiv {
/*  width: 60%;
  margin: auto;
  margin-top: 30px;*/
}

/* #070707*/

.feedlist {
  text-decoration: none;
  background-color: white;
}

/* If the screen is greater than X, then use these css settings */
@media only screen and (min-width: 650px) {

  .feeditem {
    max-width: 600px;

    background-color: white;
    border-radius: 10px;
    border-width: 1px;
    border-style: solid;
    border-color: #D0D0D0;

    width: 100%;
    margin: auto;
    margin-bottom: 20px;
    margin-top: 20px;
    /*box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.19);*/
    font-family: "Futura";
    color: #3C3C3C;
    font-weight: normal;

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
  }

}

/* If the screen is small than X, then use these css settings. */
@media screen and (max-width: 650px) {

  .feeditem {
    background-color: white;
    width: 100%;
    /*margin: auto;*/
    margin-bottom: 20px;

    /*font-family: "Futura";
    color: #3C3C3C;
    font-weight: normal;*/

    display: flex;
    flex-direction: column;
    /*align-items: center;*/
    flex-wrap: nowrap;
  }

}

/* If the screen size more than ... */
@media screen and (min-width: 800px) {
  .feeditem {
    text-align: left;
    margin-left: 10%;
  }
}


.feeditem_footer {
  align-self: center;
  padding-bottom: 15px;
}
.feeditem_child {
  /*flex-basis: 100%;
  flex-grow: 1;*/
}
.playerimages {
  margin-top: 10px;
  margin-left: 10px;

  display: flex;
  flex-direction: row;
}
.playerimages > * {
  margin-right: 10px;
}

.gameimage {
  /*width: 200px;*/
  /*margin: auto;*/
  /*object-fit: contain;*/
  /*width: 100%;*/
  /*height: 200px;*/
  /*background-size: cover;*/
  /*background-size:100% 100%;*/
  object-fit: cover;
  height: 100%;
  width: 100%;
  /*left: 0;
  position: absolute;
  top: 0;*/
}

.gameimage_placeholder {
  background-color: #4f5d6b;
  padding: 100px 20px;

  font-family: "Roboto Medium";
  font-size: 60px;
  line-height: 70px;

  text-align: center;
  text-transform: uppercase;
}
.gameimage_placeholder p {
  margin: auto;
  color: #d0d0d0;
}

/* https://css-tricks.com/snippets/css/prevent-long-urls-from-breaking-out-of-container/ */
.dont-break-out {
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;

}

/*
@media (max-width: 480px) {
  .feeditem {
    background-color: red;
    padding: 0px;
    margin: 0px;
  }
}
*/
/*
@media screen and (max-width: 500px) {
  .gamelist_dropdownlist {width: 80%;}
  .gamelist_multiselect {width: 80%;}
  .feeditem {width: 90%;}
  .gameimage  {width: 50%;}

}

@media screen and (min-width: 1000px) {
  .header_button {font-size: 16px;}
}
*/

/*.feeditem > a:first-child {font-weight: lighter;font-size: 20px;color: #3C3C3C;}*/

.header  {margin: auto;width: auto;text-align: center;}
.header_image_div {
  width: auto;
  text-align: left;
  margin-left: 10%;
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
}
.header_image {
  width: 50px;
}
.header_welcome {
  color: white;
  font-family: "Futura";
  font-weight: normal;
  font-size: 13px;
  margin-left: 20px;
}
.header_button {
  background-color: #93AEC6;
  border: none;
  color: white;
  width: 80%;
  margin: auto;
  margin-bottom: 10px;
  text-align: left;
  outline: none;
  font-family: "Futura";
  font-weight: normal;
  font-size: 14px;
  padding: 5px;
  border-radius: 5%;
  transition: 0.25s;
}
.header_button:hover  {
  background-color: #527DA4;
  color: white;
}

.div_gameimage {
  margin: auto;
  width: auto;
  text-align: center;
}
.playerimage {
  width: 200px;
  margin: auto;
  border-radius: 50%;
}
.div_playerimage {
  margin: auto;
  text-align: center;
  margin-bottom: 40px;
}

/* in ViewGame ... shouldn't be here */
.gameActivityHeader {
  display: flex;
/*  margin: auto;
  text-align: center;
  */
  justify-content: center;
  margin-bottom: 40px;
}
.gameActivityHeader_child {
  flex-basis: 33%;
  text-align: center;
}
.gameActivityHeader_right {
  margin-top: 100px;
  margin-left: 20px;
  text-align: left;
}

.scoreswrapper {
  padding-left: 35px;
  padding-top: 20px;
}
.score_row_playername {
  width: 100px;

}
.score_row_playerscore {
  padding-left: 20px;
  text-align: right;
}
